import {BrowserModule, Title} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppPostRoutingModule, AppPreRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AccountModule} from './modules/account/account.module';
import {AdminRoutingModule} from './modules/admin/admin-routing.module';
import {AccountRoutingModule} from './modules/account/account-routing.module';
import {SponsorshipRoutingModule} from './modules/sponsorship/sponsorship-routing.module';
import 'moment/locale/fr';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import {
    CapitalizePipe,
    CoreModule,
    DateService,
    HttpJwtInterceptor,
    InvoiceService,
    LocalErrorHandler, MobileService,
    MsToolsBillingService, MsUtilsS3Service,
    ReplaceLineBreaksPipe
} from '@isifid/core';

import {environment} from '../environments/environment';

// Angular material
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatToolbarModule} from '@angular/material/toolbar';

// Add FR as locale
import {DatePipe, registerLocaleData, TitleCasePipe} from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import * as Sentry from '@sentry/angular';
import {getFrenchPaginatorIntl} from './shared/helpers/french.paginator';
import {BootstrapService} from './shared/services/bootstrap.service';
import {ExcelService, RewardFromFileService} from '@isifid/reward';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {PublicModule} from './modules/public/public.module';
import {PublicRoutingModule} from './modules/public/public-routing.module';
import {MiscRoutingModule} from './modules/misc/misc-routing.module';
import {MiscModule} from './modules/misc/misc.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ConsumersModule } from './modules/consumers/consumers.module';

registerLocaleData(localeFr, 'fr');

Sentry.init({
    dsn: environment.sentryUrl,
    ignoreErrors: [
        'Non-Error exception captured'
    ],
    beforeSend(event): any {
        // Update the environment
        if (environment.production) {
            event.environment = 'production';
        } else if (window.location.origin.startsWith('http://localhost:')) {
            return null;
        } else if (environment.apiUrl.indexOf('.dev.') !== -1) {
            event.environment = 'development';
        } else {
            event.environment = 'staging';
        }

        event.release = environment.version;
        return event;
    }
});

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // Pre routes
        // Must be first to display home
        AppPreRoutingModule,
        // angular material
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        CoreModule.forRoot(environment),
        // Account
        AccountModule,
        AccountRoutingModule,
        // Admin
        AdminRoutingModule,
        // Consumers
        ConsumersModule,
        // Misc
        MiscModule,
        MiscRoutingModule,
        // Public
        PublicModule,
        PublicRoutingModule,
        // Sponsorship
        SponsorshipRoutingModule,
        // Shared
        SharedModule,
        // Post routes
        // Must be last to catch 404 components
        AppPostRoutingModule
    ],
    providers: [
        BootstrapService,
        CapitalizePipe,
        DatePipe,
        TitleCasePipe,
        DateService,
        ExcelService,
        InvoiceService,
        MobileService,
        MsToolsBillingService,
        MsUtilsS3Service,
        RewardFromFileService,
        ReplaceLineBreaksPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpJwtInterceptor,
            multi: true
        },
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'fr-FR'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        },
        {
            provide: MatPaginatorIntl,
            useValue: getFrenchPaginatorIntl()
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: environment.duration }
        },
        Title,
        {
            provide: ErrorHandler,
            useClass: LocalErrorHandler
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
