import {Injectable} from '@angular/core';
import {AuthenticationService, CrispService, GiftUser, UserService} from '@isifid/core';
import {BehaviorSubject, forkJoin, skip} from 'rxjs';
import {GiftService} from './gift.service';
import {UIService} from './ui.service';
import {OperationsService} from './operations.service';
import {SponsorshipService} from './sponsorship.service';
import {GiftUserService} from './gift-user.service';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class BootstrapService {
    public isInitialised: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public giftUserUuid: string | null;
    private giftUser: GiftUser;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private giftService: GiftService,
        private giftUserService: GiftUserService,
        private readonly crispService: CrispService,
        private readonly userService: UserService,
        private readonly operationsService: OperationsService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly uiService: UIService
    ) {
        // When we enter bootstrap, the app is not initialised
        this.isInitialised = new BehaviorSubject<boolean>(false);

        // Logout and clear all data if route is /auth
        if (window.location.pathname === '/auth') {
            this.authenticationService.logout(false, false);
        }

        // Init the app if user has auth tokens
        if (this.authenticationService.isAuth.value) this.init();

        // We skip the first value as it's emitted when we init the app
        // We deal with the first value above
        this.authenticationService.isAuth.pipe(skip(1)).subscribe((isAuth) => {
            // Init the app if is auth but not init
            if (isAuth && !this.isInitialised.value) {
                this.userService.initUser(this.authenticationService.getRefreshToken())
                    .subscribe(() => this.init());
            }

            // Destroy the init if it was initialized but auth has been destroyed
            if (!isAuth && this.isInitialised.value) this.destroy();
        });

        // Get GiftUser
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
    }

    public init() {
        const user = this.userService.getUser();
        if (!user) {
            this.authenticationService.logout(true, false);
            return;
        }
        if (!this.giftUserUuid && !this.giftUser?.uuid) this.giftUserUuid = user.uuid;

        const initObservablesArray = [
            this.giftUserService.init(this.giftUserUuid),
            this.giftService.init(user.clientId),
            this.operationsService.init(user.clientId),
            this.sponsorshipService.initEntities(user.clientId)
        ];

        // Init manager if needed
        if (this.userService.hasRole('GIFT_MANAGER') || this.userService.hasRole('GIFT_DIRECTOR')) {
            initObservablesArray.splice(0, 1, this.giftUserService.init(user.uuid));
        }

        this.crispService.init(user.clientId, '8a412d88-127b-4729-b292-f0718db417cc').subscribe();

        // Init settings, client and operations
        forkJoin(initObservablesArray).subscribe({
            next: () => {
                this.crispService.initUser(user, this.giftService.client?.name).subscribe();
                this.crispService.setValue('session', ['branchList', this.giftUser?.branchList || ['-1']]).subscribe();

                this.sponsorshipService.initSponsorship();
                this.uiService.init();
                this.giftService.checkIfAccountComplete();
                this.isInitialised.next(true);
            },
            error: () => {
                console.error('Error while initClient and/or initGiftSettingsByClientId and/or initOperationsByClientId');
                this.authenticationService.logout(true, false);
            }
        });
    }

    destroy() {
        // We set it first so the app knows we are not initialised anymore
        this.isInitialised.next(false);

        this.giftService.destroy();
        this.sponsorshipService.destroy();
        this.giftUserService.destroy();
        this.uiService.destroy();
        this.giftUserUuid = null;
    }
}

