<div class="container-fluid p-5">
    <div class="row mt-3 mb-2 text-center">
        <h1>Mon budget Récompenses</h1>
        <p class="mt-5">
            Ce budget vous donne la liberté de récompenser certains clients<br>
            selon un barème et une allocation prédéfinis par votre direction.
        </p>
    </div>


    <div class="row">
        @if (!loading) {
            <div class="col-10 col-lg-8 mx-auto">
                <p [hidden]="rows" class="text-center">Vous n'avez aucun budget récompense alloué pour le moment.</p>

                <table [dataSource]="rows" [hidden]="!rows || !rows.length" aria-describedby="rows"
                       class="my-5 w-100" mat-table>

                    <ng-container matColumnDef="operation">
                        <th *matHeaderCellDef id="operation" class="text-purple text-center" mat-header-cell> Opération</th>
                        <td *matCellDef="let row" class="text-grey text-center" mat-cell
                            [ngClass]="{'clickable': row.amountRemaining >= row.rewardAmount}"
                            [queryParams]="{operationId: row.operationId, offerId: row.offerId}"
                            [routerLink]="row.amountRemaining >= row.rewardAmount ? ['/consumers/rewards/create'] : null">
                            {{row.operation}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="reward">
                        <th *matHeaderCellDef id="reward" class="text-purple text-center" mat-header-cell> Récompense</th>
                        <td *matCellDef="let row" class="text-grey text-center clickable" mat-cell
                            [ngClass]="{'clickable': row.amountRemaining >= row.rewardAmount}"
                            [queryParams]="{operationId: row.operationId, offerId: row.offerId}"
                            [routerLink]="row.amountRemaining >= row.rewardAmount ? ['/consumers/rewards/create'] : null">
                            {{row.rewardAmount}} €
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th *matHeaderCellDef id="amount" class="text-purple text-center" mat-header-cell>
                            Allocation restante
                        </th>
                        <td *matCellDef="let row" class="text-grey text-center clickable" mat-cell
                            [ngClass]="{'clickable': row.amountRemaining >= row.rewardAmount}"
                            [queryParams]="{operationId: row.operationId, offerId: row.offerId}"
                            [routerLink]="row.amountRemaining >= row.rewardAmount ? ['/consumers/rewards/create'] : null">
                            {{row.amountRemaining}} €
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="end">
                        <th *matHeaderCellDef id="end" class="text-purple text-center" mat-header-cell> Échéance</th>
                        <td *matCellDef="let row" class="text-grey text-center clickable" mat-cell
                            [ngClass]="{'clickable': row.amountRemaining >= row.rewardAmount}"
                            [queryParams]="{operationId: row.operationId, offerId: row.offerId}"
                            [routerLink]="row.amountRemaining >= row.rewardAmount ? ['/consumers/rewards/create'] : null">
                            {{row.endAt | date: 'shortDate':'+2':'fr'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th *matHeaderCellDef id="action" class="text-purple text-center" mat-header-cell></th>
                        <td *matCellDef="let row" class="text-grey text-center clickable" mat-cell
                            [ngClass]="{'clickable': row.amountRemaining >= row.rewardAmount}">
                            @if (row.amountRemaining >= row.rewardAmount) {
                                <a [queryParams]="{operationId: row.operationId, offerId: row.offerId}"
                                   [routerLink]="['/consumers/rewards/create']"
                                   class="clickable">
                                    Récompenser
                                </a>
                            } @else {
                                <span>Allocation épuisée</span>
                            }
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                </table>
            </div>
        }
    </div>
</div>
