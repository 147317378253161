<div class="container-fluid m-0 text-center">
    @if (loading) {
        <div>
            <mat-spinner class="mx-auto" />
        </div>
    }

    <div class="my-5">
        <h1>{{ title }}</h1>
    </div>

    @if (!isManagerAccountCompleteAtLoading) {
        <div class="row">
            <h2 class="mb-5">
                Pour activer votre compte MANAGER, merci de compléter votre(vos) code(s) agence / point(s) de vente.
            </h2>
        </div>
    }

    <!-- Manager ------------------------------------------------------------------------------------------- -->
    @if (userService.hasRole('GIFT_MANAGER', user)) {

        <div class="row mb-5">
            <p class="px-5">Votre(vos) code(s) agence / point(s) de vente</p>
            <div class="col mx-auto">
                <mat-chip-grid #chipList aria-label="Code agences" class="border">
                    @for (branch of branchList; track branch) {
                        <mat-chip-row (removed)="removeBranch(branch)">
                            {{branch}}
                            <button matChipRemove>
                                <i aria-hidden="true" class="fas fa-backspace"></i>
                            </button>
                        </mat-chip-row>
                    }
                    <input (matChipInputTokenEnd)="addBranch($event)"
                           [matChipInputAddOnBlur]="true"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [maxLength]="5"
                           placeholder="Ajout Code agence ou Point de vente (en chiffres)">
                </mat-chip-grid>
                @if (!branchList || branchList.length < 1) {
                    <p class="h6">
                        Merci de saisir au moins un code agence ou point de vente (en chiffres)
                    </p>
                }
            </div>

            <div class="col ps-3">
                @if (!loading) {
                    <button (click)="updateManagerBranchList()"
                            class="btn btn-validate mt-2">
                        Mettre à jour
                    </button>
                } @else {
                    <button class="btn btn-validate mt-2" disabled>
                        <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                    </button>
                }
            </div>
        </div>
    }

    <!-- Director ------------------------------------------------------------------------------------------ -->
    @if (userService.hasRole('GIFT_DIRECTOR', user) && !loading) {
        <div class="row my-4">
            @if (hideTable) {
                <p>
                    @if (!userService.hasRole('GIFT_HQ')) {
                            <span>Aucun manager du réseau n'a été rattaché à vous pour le moment.</span>
                    } @else {
                        <span>
                            Aucun manager du réseau n'a été rattaché à {{user.email}} pour le moment.
                        </span>
                    }
                </p>
            }

            @if (addManagerForm) {
                <form (ngSubmit)="addManager()" [formGroup]="addManagerForm" class="row">
                    <mat-form-field class="my-3">
                        <mat-label>Ajouter des managers avec leurs emails</mat-label>
                        <input matInput
                               formControlName="email"
                               id="email"
                               type="email"/>
                    </mat-form-field>

                    <div class="col ps-3">
                        <button class="btn btn-validate" type="submit">
                            Ajouter
                        </button>
                    </div>
                </form>
            }

            @if (!hideTable) {
                <div class="mt-3">
                    <p>Vous pouvez cliquer sur un(e) manager du réseau pour voir son équipe</p>
                </div>
            }
        </div>
    }

    <!-- Table --------------------------------------------------------------------------------------------- -->
    <table [dataSource]="dataSource" [hidden]="hideTable" aria-describedby="mystaff" class="my-5 w-100"
           mat-table matSort>

        <ng-container class="py-5" matColumnDef="branchCode">
            <th *matHeaderCellDef id="branchCode" mat-header-cell mat-sort-header>Point de vente</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.branchCode}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="firstName">
            <th *matHeaderCellDef id="firstName" mat-header-cell mat-sort-header>Prénom</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.firstName}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="lastName">
            <th *matHeaderCellDef id="lastName" mat-header-cell mat-sort-header>Nom</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.lastName}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="email">
            <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.email}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="updatedAt">
            <th *matHeaderCellDef id="updatedAt" mat-header-cell mat-sort-header>Modifié le</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.updatedAt | date: 'shortDate':'+2':'fr'}}
            </td>
        </ng-container>


        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr (click)="openDialog(row)" *matRowDef="let row; columns: displayedColumns;"
            [class.clickable]="userService.hasRole('GIFT_DIRECTOR', user)"
            mat-row>
        </tr>
    </table>

    <mat-paginator
            [hidden]="hideTable || dataSource.data.length < 25"
            [pageSizeOptions]="[25, 50, 100]"
            aria-label="Select page of user"
            showFirstLastButtons />

    @if (isManagerAccountCompleteAtLoading) {
        <div class="row mt-2">
            <div class="col">
                <button (click)="extractUsers()"
                        [disabled]="hideTable || loading"
                        class="btn btn-validate col-2 mx-auto">
                    @if (!loading) {
                        <i class="fas fa-file-excel mx-2" aria-hidden="true" ></i> Export Excel
                    } @else {
                        <em class="fas fa-spinner fa-spin" aria-hidden="true"></em>
                    }
                </button>
            </div>
        </div>
    }

</div>
