import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {CacheService, UserCached, UserService} from '@isifid/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GiftService} from "../../../shared/services/gift.service";

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html'
})
export class FavoriteComponent {
    backgroundImg = environment.cdnUrl + '/gift/img/account/supportBackground.png';
    showForm: boolean;
    isChecked: boolean;
    private user: UserCached;
    private token: string;

    constructor(
        private readonly cacheService: CacheService,
        private readonly location: Location,
        private readonly giftService: GiftService,
        private readonly userService: UserService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.user = this.userService.getUser();
        this.route.queryParams.subscribe(s => {
            this.showForm = s['force'] === 'true';

            this.loadFavorite();
            // Temporarily set up lastLoggedInAt because if null, it redirects to this page from HP
            if (!this.user.lastLoggedInAt) this.userService.updateUserFromObject({uuid: this.user.uuid, lastLoggedInAt: new Date()});
        });
    }

    loadFavorite(): void {
        this.token = this.cacheService.getContent('pUrlToken', false);

        const search = '?provider=purl' + '&uuid=' + this.user.uuid + '&token=' + this.token;
        // Update current url so the user can simply add it to favorite
        this.location.replaceState('/auth' + search);
    }

    goHome():   void {
        this.giftService.isAccountComplete = true;
        this.router.navigate(['/home']).then();
    }
}
