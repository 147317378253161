@if (isAppReady()) {
    <mat-sidenav-container [hasBackdrop]="true">
        <mat-sidenav
            mode="over"
            position="end"
            [(opened)]="uiService.isSidenavOpened"
            class="position-fixed text-white"
            [class]="router.url.includes('/admin') ? 'bg-menu ps-4' : 'bg-sidenav text-end ps-5'">
            <app-sidenav />
        </mat-sidenav>

        <mat-sidenav-content class="container-fluid g-0 pb-5 min-vh-100 max-vw-100">
            @if (isInitialised) {
                @if (!environment.production) {<app-banner />}
                <app-header />
            }
            <router-outlet />
        </mat-sidenav-content>
    </mat-sidenav-container>
}
