<div class="news-card w-100 d-flex flex-column text-white py-4 ps-4">

    <div class="d-flex flex-row justify-content-between ps-2 pe-4">
        <img alt="horn icon" height="50" src="../../../../../assets/icons/bullhorn.svg" width="50">

        @if (lastNewsAt) {
            <p class="fst-italic small">
                Mis à jour le {{lastNewsAt | date: 'dd/MM/yy'}}
            </p>
        }
    </div>

    <div class="mt-4 mb-3 ps-2">
        <h2>Actualités</h2>
    </div>


    <div class="news">
        @for (n of news; track n.id) {
            <div class="my-2">
                <span class="title-border-left fw-bold ps-2">{{n.title}}</span>
                <p class="news-content my-0 px-2" [innerHTML]="removeImg(n.content)"></p>
                <a (click)="openNews(n)" class="text-light-blue ps-2">Lire la suite ></a>
            </div>
        }
    </div>

</div>
