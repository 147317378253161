@if (supportForm) {
    <div [style.background-image]="'url(\'' + backgroundImg + '\')'"
         class="container-fluid support bg-custom p-2 p-md-5 min-vh-100 max-vw-100">
        <div class="container p-5 mx-auto bg-white">

            <div class="my-5">
                <h1> Support </h1>
            </div>

            <p>Vous pouvez remplir le formulaire ci-dessous ou contacter directement notre service client via le
                chat en bas à droite de votre écran.</p>
            <form (ngSubmit)="submit()" [formGroup]="supportForm" class="m-0 p-0">

                <div class="row">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Votre nom :</mat-label>
                        <input matInput
                               formControlName="name"
                               id="name"
                               maxlength="226"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Email :</mat-label>
                        <input matInput
                               formControlName="email"
                               id="email"
                               maxlength="226"
                               type="email"/>
                    </mat-form-field>
                </div>

                <div class="row my-3">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Sélectionnez votre question :</mat-label>
                        <mat-select (selectionChange)="showAnswer($event)" formControlName="subject">
                            @for (f of faq; track f.question) {
                                <mat-option [value]="f.question">
                                    {{f.question}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>

                @if (subjectIndex || subjectIndex === 0) {
                    <div class="row my-3">
                        <div [innerHTML]="faq[subjectIndex].answer" class="col"></div>
                    </div>
                }

                <div class="row my-3">
                    <mat-form-field appearance="fill" class="col">
                        <mat-label>Message :</mat-label>
                        <textarea matInput
                                  formControlName="message"
                                  id="message"
                                  rows="10"></textarea>
                    </mat-form-field>
                </div>
            </form>

            @if (!loading) {
                <button (click)="submit()"
                        class="btn btn-validate"
                        type="submit">Envoyer
                </button>
            } @else {
                <button class="btn btn-validate" disabled>
                    <em aria-hidden="true" class="fas fa-spinner fa-spin"></em> envoi en cours
                </button>
            }
        </div>
    </div>
}
