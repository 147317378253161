@if (loading) {
    <div class="container-fluid py-5">
        <div class="row">
            <div class="col text-center">
                <mat-spinner class="mx-auto" />
            </div>
        </div>
    </div>
}

<div [hidden]="loading" class="container-fluid m-0">

    <h1 class="my-5 text-center">Suivi Operations</h1>

    <table [dataSource]="dataSource" [hidden]="dataSource.data.length === 0"
           aria-describedby="operations" class="my-5 w-100" mat-table matSort>

        <ng-container class="py-5" matColumnDef="name">
            <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header>Opération</th>
            <td *matCellDef="let operation" class="text-secondary" mat-cell>{{operation.name}}</td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="consumption">
            <th *matHeaderCellDef id="consumption" mat-header-cell mat-sort-header>
                Clients récompensés
            </th>
            <td *matCellDef="let operation" class="text-secondary" mat-cell>{{operation.consumption}}</td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="startDate">
            <th *matHeaderCellDef id="startDate" mat-header-cell mat-sort-header>Date de début</th>
            <td *matCellDef="let operation" class="text-secondary" mat-cell>
                {{operation.startDate | date: 'shortDate':'+2':'fr'}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="endDate">
            <th *matHeaderCellDef id="endDate" mat-header-cell mat-sort-header>Date de fin</th>
            <td *matCellDef="let operation" class="text-secondary" mat-cell>
                {{operation.endDate | date: 'shortDate':'+2':'fr'}}
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>


