<div class="container-fluid p-0">

    <div class="row m-5 text-center">

        <div class="col my-5">
            <h1>Rechercher un client</h1>
        </div>

        <app-search-bar />
    </div>

</div>
