class Faq {
    question: string;
    answer: string;
}

export const faq: Array<Faq> = [
    {
        question: 'À quoi vous sert l’assistant Gift ?',
        answer: 'L’assistant Gift vous sert essentiellement à récompenser vos clients qui ont souscrit à des produits sélectionnés par votre direction. Cela ' +
            'peut également récompenser des comportements telle la recommandation si votre banque a décidé d’encourager ce type de comportement. <br>Les ' +
            'opérations éligibles sont répertoriées dans “Opérations en cours”.'
    },
    {
        question: 'En quoi consistent les récompenses attribuées aux clients ?',
        answer: 'Les récompenses sont toujours exprimées en euros, soit à consommer dans des sites de e-commerce partenaires, soit versées en cash sur votre ' +
            'compte bancaire personnel.'
    },
    {
        question: 'Comment m’enregistrer sur Gift ?',
        answer: 'Pour ouvrir un compte Gift, il faut entrer votre adresse email professionnelle et le code de votre agence.<br>' + 'Pour une connexion ' +
            'simplifiée, il suffit d\'ajouter ' +
            '<a href="/account/favorite">ce lien</a>' + ' de connexion à vos favoris et vous serez ainsi automatiquement connecté(e).<br>Pour ajouter un ' +
            'site à vos favoris, plusieurs options s\'offrent à vous, au choix : Appuyer sur CTRL + D (CMD + D pour les utilisateurs de mac) ou Appuyer sur ' +
            'l\'étoile en haut à droite. Une fois ' +
            '<a href="/account/favorite">ce lien</a>' + ' de connexion automatique ajouté à vos favoris, il vous suffira de le retrouver dans la liste de ' +
            'favoris de votre navigateur, de cliquer et vous serez ainsi connecté(e). Il ne sera plus nécessaire d’entrer vos informations et d’utiliser la ' +
            'connexion par email.'
    },
    {
        question: 'À quoi sert mon enregistrement sur Gift?',
        answer: 'Cet enregistrement sert d’une part à me connecter sans m’identifier mais également à suivre mes résultats et être récompensé selon mes ' +
            'performances lors d’éventuels challenges organisés par ma direction.'
    },
    {
        question: 'Je change d’agence, dois-je modifier mon compte ?',
        answer: 'Oui, vous devez changer votre code agence. Voici ' + '<a href="/account/user">le lien</a>' + ' qui vous permettra de le faire'
    },
    {
        question: 'Où puis-je m’informer sur les récompenses à consommer dans les sites de e-commerce ?',
        answer: 'Un lien figure en bas de la homepage de votre assistant Gift. Vous pouvez également cliquer ci-dessous pour découvrir les partenaires, ' +
            'les modalités…<br>' + '<a href="{{ marketplaceUrl }}/demo" target="_blank">{{ marketplaceUrl }}/demo</a>'
    },
    {
        question: 'Comment récompenser mon client pour une opération en cours ?',
        answer: 'Il vous suffit de cliquer sur l\'opération en cours et de saisir les coordonnées de votre client. Si la récompense est à consommer sur les ' +
            'sites de e-commerce partenaires, un email et un sms lui donnant accès à sa récompense lui seront envoyés instantanément.'
    },
    {
        question: 'Mon client n’arrive pas à utiliser sa récompense ?',
        answer: 'Il peut se rendre sur la rubrique Assistance sur son site de récompense ' +
            '<a href="{{ marketplaceUrl }}/support" target="_blank">{{ marketplaceUrl }}/support</a><br>' +
            'Au problème soulevé, il trouvera la réponse soit dans les FAQ soit en envoyant un email au support.<br>' +
            'Mais vous pouvez aussi envoyer un email depuis la rubrique Assistance sur Gift  ' +
            '<a href="/support">{{ giftUrl }}/support</a><br>' +
            ' ou en utilisant le chat Gift en bas d’écran à droite.'
    },
    {
        question: 'Mon client n’a pas d’adresse email, peut-il être récompensé autrement ?',
        answer: 'L\'adresse email est obligatoire. En effet, nos partenaires sont e-commerçants. Il s\'agit donc d\'une récompense 100% digitale. Par email, ' +
            'il pourra se connecter, commander et recevoir ses bons.'
    },
    {
        question: 'Est-ce que mon client peut cumuler plusieurs récompenses ?',
        answer: 'Oui, les récompenses sont cumulables'
    },
    {
        question: 'Mon client n\'a pas reçu son email d\'accès, comment faire ?',
        answer: 'Il vous suffit de rechercher votre client par son N° de portable pour accéder à son dossier puis lui renvoyer ses accès.'
    },
    {
        question: 'Je me suis trompé dans les informations enregistrées, j\'ai inscrit un filleul en tant que parrain ?',
        answer: 'Vous pouvez contacter notre support par le formulaire de contact ou le tchat à votre disposition.'
    },
    {
        question: 'Je me suis trompé dans les coordonnées de mon client, comment puis-je procéder ?',
        answer: 'Il vous suffit de rechercher votre client grâce à son N° de portable puis d\'accéder à sa fiche client pour mettre à jour ses données. ' +
            'Appuyez ensuite sur la touche  Renvoyer l\'accès si c\'est l\'adresse email et/ou le numéro de mobile qui ont été mis à jour.'
    },
    {
        question: 'J’ai récompensé un client qui ne correspondait pas aux critères d’éligibilité, comment faire pour annuler ?',
        answer: 'Veuillez contacter notre support.'
    },
    {
        question: 'Autre',
        answer: '<br><br>'
    }
];
