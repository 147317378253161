import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Consumer, GiftUser, SponsorshipUser} from '@isifid/core';
import {filter} from 'rxjs/operators';
import {GiftUserService} from '../../../../shared/services/gift-user.service';
import {finalize} from 'rxjs';

@Component({
    selector: 'app-sponsor-age-form',
    templateUrl: './sponsor-age-form.component.html'
})
export class SponsorAgeFormComponent implements OnInit {
    @Input() consumer: Consumer;
    @Input() sponsorshipUser: SponsorshipUser;
    @Output() updateSponsorshipUser = new EventEmitter<SponsorshipUser>();
    giftUser: GiftUser;
    sponsorAgeForm: FormGroup;
    loading: boolean;

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly formBuilder: FormBuilder,
        private readonly giftUserService: GiftUserService
    ) {
    }

    ngOnInit(): void {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
        this.sponsorAgeForm = this.formBuilder.group({
            checkAge: [false, Validators.requiredTrue]
        });
    }

    beComeSponsor(): void {
        if (this.loading || this.sponsorAgeForm.invalid) return;
        this.loading = true;

        this.sponsorshipService.becomeSponsorshipUser(this.consumer, this.sponsorshipUser)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: s => this.updateSponsorshipUser.emit(s)
            });
    }
}
