<div class="container-fluid">
    <div class="container mx-auto">
        <div class="row mt-5">
            <div class="col mx-auto text-center">
                <h1>ERREUR 404</h1>
                <img [src]="cdnUrl + 'notFound/stars.jpg'" alt="stars" class="stars" height="auto" width="auto">
            </div>
        </div>
        <div class="row">
            <div class="col mx-auto text-center">
                <img [src]="cdnUrl + 'notFound/car.jpg'" alt="peugeot 404" class="img-fluid" height="319" width="900">
            </div>
        </div>
        <div class="row my-5">
            <div class="col mx-auto text-center">
                <h2>Oops la page que vous cherchez n'existe pas.</h2>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col mx-auto text-center">
                <button class="btn btn-black" routerLink="/home">Retour au menu</button>
            </div>
        </div>
    </div>
</div>
