<div class="w-100 mt-5 pt-3">
    @if (form) {
        <form (ngSubmit)="dispatchSubmit()" [formGroup]="form">
            <mat-form-field appearance="fill" class="py-3">
                <mat-label>N° de portable de votre client</mat-label>
                <input matInput
                       (change)="getConsumer()"
                       formControlName="mobile"
                       id="mobile"
                       type="tel"/>
            </mat-form-field>

            <mat-form-field appearance="fill" class="py-3">
                <mat-label>Email de votre client</mat-label>
                <input matInput
                       formControlName="email"
                       id="email"
                       type="email"/>
            </mat-form-field>

            @if (consumersSearch?.length > 0) {
                @for (consumer of consumersSearch; track consumer.id; let index = $index) {
                    <div class="py-3">
                        <a (click)="selectConsumer(consumer)" [id]="'consumer' + index" class="clickable">
                            {{ consumer.email }} - {{ consumer.mobile }}
                        </a>
                    </div>
                }
            }

            @if (!!giftService.getConfigurationValue('mandatoryExternalId')) {
                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>
                        @if (giftService.giftNetworkVariables?.idName) {{{giftService.giftNetworkVariables?.idName | titlecase }}}
                        @else {ID * à {{giftService.giftNetworkVariables?.idLength || 30}} chiffres)}
                    </mat-label>
                    <input matInput
                           [maxLength]="giftService.giftNetworkVariables?.idLength || 30"
                           [minLength]="giftService.giftNetworkVariables?.idLength || 5"
                           formControlName="externalId"
                           id="externalId"
                           type="text"/>
                </mat-form-field>
            }

            @if (showExtendedForm) {
                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>Civilité</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option value="1">M.</mat-option>
                        <mat-option value="2">Mme</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>Nom</mat-label>
                    <input matInput
                           (blur)="capitalizeInput($event)"
                           formControlName="lastName"
                           id="lastName"
                           type="text"/>
                </mat-form-field>

                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>Prénom</mat-label>
                    <input matInput
                           (blur)="capitalizeInput($event)"
                           formControlName="firstName"
                           id="firstName"
                           type="text"/>
                </mat-form-field>

                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>Né(e) le</mat-label>
                    <input matInput formControlName="birthDate" id="birthDate" [matDatepicker]="birthDate"/>
                    <mat-datepicker-toggle matIconSuffix [for]="birthDate" />
                    <mat-datepicker #birthDate />
                    <mat-error>Date de naissance invalide.</mat-error>
                </mat-form-field>
            }
            @if (customReward) {
                @for (custom of customRewardFields; track index; let index = $index) {
                    <div [hidden]="giftService.getConfigurationValue('simpleReward') === false && !showExtendedForm">
                        @if (custom.type === 'link') {
                            <div class="row py-3">
                                <div class="col">
                                    @if (custom.placeholder) {<p>{{custom.placeholder}}</p>}
                                    <a [href]="custom.defaultValue" target="_blank">{{custom.name}}</a>
                                </div>
                            </div>
                        } @else if (custom.type !== 'checkbox' && custom.type !== 'link') {
                            <div class="row py-3">
                                <mat-form-field appearance="fill" class="col">
                                    <mat-label>{{custom.name}}</mat-label>
                                    <input matInput
                                           [formControlName]="custom.name"
                                           [class.not-empty]="form.controls[custom.name].value"
                                           [required]="custom.required === 'true'"
                                           [type]="custom.type === 'email' ? 'email' : 'text'"
                                           [id]="custom.name + custom.order"
                                           [value]="custom.defaultValue.toString()"
                                           [minlength]="custom.minLength || 0"
                                           [maxlength]="custom.maxLength || 226"/>
                                    <mat-error>{{getErrorMessage(custom.name)}}</mat-error>
                                    @if (custom.hint) {
                                        <mat-hint>{{custom.hint}}</mat-hint>
                                    }
                                </mat-form-field>
                            </div>
                        } @else if (custom.type === 'checkbox') {
                            <div class="row py-3">
                                <div class="col">
                                    <input [formControlName]="custom.name"
                                           id="{{custom.name + custom.order}}"
                                           required="{{custom.required === true}}"
                                           type="checkbox">
                                    <label class="ms-2 check-box" for="{{custom.name + custom.order}}">{{custom.name}}</label>
                                    <br>

                                    @if (custom.hint) {
                                        <p class="text-center">{{custom.hint}}</p>
                                    }
                                    @if (form.get([custom.name]).invalid && form.get([custom.name]).touched) {
                                        <mat-error class="px-3">
                                            {{getErrorMessage(custom.name)}}
                                        </mat-error>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            }

            @if (branchList.length > 1) {
                <mat-form-field appearance="fill" class="py-3">
                    <mat-label>Merci de sélectionner votre code agence ou point de vente</mat-label>
                   <mat-select formControlName="branchCode">
                       @for (branch of branchList; track branch) {
                           <mat-option [value]="branch">
                               {{giftService.convertBranchCodeToString(branch)}}
                           </mat-option>
                       }
                   </mat-select>
                </mat-form-field>
            }
            @if (!loading) {
                @if (showExtendedForm) {
                    <button [disabled]="!formIsValid()" type="submit" class="btn btn-validate d-block mx-auto mt-2">
                        Valider
                    </button>
                } @else {
                    <button [disabled]="!formIsValid()" type="button" (click)="displayExtendedForm()" class="btn btn-validate d-block mx-auto mt-2">
                        Valider
                    </button>
                }
            }
        </form>
    }
</div>
