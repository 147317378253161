<div class="p-5">

    <h1 class="title-border-left ps-2">{{news.title}}</h1>

    <div class="m-0 ps-2">
        <p [innerHTML]="news.content" class="my-4 ps-2"></p>
    </div>

    <button class="btn btn-validate m-3" mat-dialog-close="true">Fermer</button>
</div>
