<h1 mat-dialog-title>Equipe de {{data.manager?.email}}</h1>
<mat-dialog-content class="mat-typography">
    @if (loading) {
        <div class="p-5">
            <mat-spinner class="mx-auto" />
        </div>
    } @else if (!loading && dataSource.data.length === 0) {
        <p>
            Aucun conseiller pour le moment
        </p>
    }

    <table [dataSource]="dataSource" [hidden]="loading || dataSource.data.length === 0"
           aria-describedby="mystaff" class="my-5 w-100" mat-table matSort>

        <ng-container class="py-5" matColumnDef="branchCode">
            <th *matHeaderCellDef id="branchCode" mat-header-cell>Point de vente</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.branchCode}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="firstName">
            <th *matHeaderCellDef id="firstName" mat-header-cell> Prénom</th>
            <td *matCellDef="let user" class="text-secondary" mat-cell>
                {{user.firstName}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="lastName">
            <th *matHeaderCellDef id="lastName" mat-header-cell> Nom</th>
            <td *matCellDef="let user" class="text-secondary" mat-cell>
                {{user.lastName}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="email">
            <th *matHeaderCellDef id="email" mat-header-cell> Email</th>
            <td *matCellDef="let user" class="text-secondary" mat-cell>
                {{user.email}}
            </td>
        </ng-container>

        <ng-container class="py-5" matColumnDef="updatedAt">
            <th *matHeaderCellDef id="updatedAt" mat-header-cell>Modifié le</th>
            <td *matCellDef="let user" class="text-grey" mat-cell>
                {{user.updatedAt | date: 'shortDate':'+2':'fr'}}
            </td>
        </ng-container>


        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator
            [hidden]="dataSource.data.length < 25"
            [pageSizeOptions]="[25, 50, 100]"
            aria-label="Select page of user"
            showFirstLastButtons />
</mat-dialog-content>
<mat-dialog-actions>
    <button class="btn btn-validate mx-auto" mat-dialog-close="true">X</button>
</mat-dialog-actions>
