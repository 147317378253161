@if (loading) {
    <div class="w-100 text-center pt-5">
        <div class="mt-5 mb-2">
            <h1> Connexion </h1>
        </div>
        <div class="fa-3x">
            <em class="fas fa-sync fa-spin"></em>
        </div>
    </div>
}
