import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MiscRoutingModule} from './misc-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {GiftCardsComponent} from './gift-cards/gift-cards.component';
import {HomeComponent} from './home/home.component';
import {HowtoComponent} from './howto/howto.component';
import {SupportComponent} from './support/support.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {DialogNewsComponent, NewsComponent} from './home/news/news.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRippleModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        DialogNewsComponent,
        GiftCardsComponent,
        HomeComponent,
        HowtoComponent,
        NewsComponent,
        NotFoundComponent,
        SupportComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MiscRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        MatDialogModule,
        MatRippleModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule
    ],
    exports: [],
    providers: []
})
export class MiscModule {
}
