import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './modules/misc/not-found/not-found.component';
import {authGuardFn, trackGuardFn} from '@isifid/core';

const preRoutes: Routes = [
    {
        path: 'admin',
        redirectTo: 'admin/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'consumers',
        redirectTo: 'consumers/search',
        pathMatch: 'full'
    },
    {
        path: 'sponsorship',
        loadChildren: () => import('./modules/sponsorship/sponsorship.module').then(m => m.SponsorshipModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(preRoutes)],
    exports: [RouterModule]
})
export class AppPreRoutingModule {
}

const postRoutes: Routes = [
    {
        path: '404',
        canActivate: [authGuardFn, trackGuardFn],
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(postRoutes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppPostRoutingModule {
}
