import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AccountComponent} from './account/account.component';
import {SearchComponent} from './search/search.component';
import {authGuardChildFn, trackGuardChildFn} from '@isifid/core';
import {RewardComponent} from './reward/reward.component';
import {TrackingComponent} from './tracking/tracking.component';

const routes: Routes = [
    {
        path: 'consumers',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'search',
                component: SearchComponent
            },
            {
                path: 'tracking',
                component: TrackingComponent
            },
            {
                path: ':id',
                component: AccountComponent
            }
        ]
    },
    {
        path: 'consumers/rewards',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: '',
                redirectTo: 'create',
                pathMatch: 'full'
            },
            {
                path: 'create',
                component: RewardComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConsumersRoutingModule {
}
