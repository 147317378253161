import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AccountRoutingModule} from './account-routing.module';
import {FavoriteComponent} from './favorite/favorite.component';
import {SharedModule} from '../../shared/shared.module';
// Qrcode
import {QrCodeModule} from 'ng-qrcode';
// Angular material
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRippleModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {OperationsTrackingComponent} from './operations-tracking/operations-tracking.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {DialogAdvisorComponent, StaffComponent} from './staff/staff.component';
import {RewardsComponent} from './rewards/rewards.component';
import {UserComponent} from './user/user.component';
import {CreditsComponent} from './credits/credits.component';
import {DialogPersonalLinkComponent} from './dialog-personal-link/dialog-personal-link.component';
import {DialogUpdateBranchCodeComponent} from './dialog-update-branch-code/dialog-update-branch-code.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    declarations: [
        CreditsComponent,
        DialogAdvisorComponent,
        FavoriteComponent,
        OperationsTrackingComponent,
        RewardsComponent,
        StaffComponent,
        UserComponent,
        DialogPersonalLinkComponent,
        DialogUpdateBranchCodeComponent
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        // Qrcode
        QrCodeModule,
        // Angular material
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatListModule,
        MatRippleModule,
        MatToolbarModule,
        MatChipsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatGridListModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule
    ],
    exports: [],
    providers: []
})
export class AccountModule {
}
