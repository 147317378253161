import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FavoriteComponent} from './favorite/favorite.component';
import {authGuardChildFn, trackGuardChildFn} from '@isifid/core';
import {OperationsTrackingComponent} from './operations-tracking/operations-tracking.component';
import {StaffComponent} from './staff/staff.component';
import {RewardsComponent} from './rewards/rewards.component';
import {UserComponent} from './user/user.component';
import {CreditsComponent} from './credits/credits.component';

const routes: Routes = [
    {
        path: 'account',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'credits',
                component: CreditsComponent
            },
            {
                path: 'favorite',
                component: FavoriteComponent
            },
            {
                path: 'staff',
                component: StaffComponent
            },
            {
                path: 'rewards',
                component: RewardsComponent
            },
            {
                path: 'user',
                component: UserComponent
            }
        ]
    },
    {
        path: 'operations',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'tracking',
                component: OperationsTrackingComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule {
}
