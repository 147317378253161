import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApiService, FormValidators, UserCached, UserService} from '@isifid/core';
import {faq} from '../../../shared/models/faq.model';
import {environment} from '../../../../environments/environment';
import {GiftService} from '../../../shared/services/gift.service';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html'
})
export class SupportComponent implements OnInit {
    backgroundImg = environment.cdnUrl + '/gift/img/account/supportBackground.png';
    faq = faq;
    loading: boolean = true;
    subjectIndex: number;
    supportForm: FormGroup;
    private user: UserCached;
    private readonly endpoint = '/services/gift/support';

    constructor(
        private readonly apiService: ApiService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly giftService: GiftService,
        private readonly userService: UserService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        let domain: string;
        if (this.giftService.client.configuration) domain = JSON.parse(this.giftService.client.configuration)?.demoDomain ?? this.giftService.client.domain;
        else domain = this.giftService.client.domain;

        this.user = this.userService.getUser();
        this.faq.forEach(f => {
            f.answer = f.answer.replace(/{{ marketplaceUrl }}/g, `https://${domain}`);
            f.answer = f.answer.replace(/{{ giftUrl }}/g, `${window.location.origin}`);
        });
        this.supportForm = this.formBuilder.group({
            name: [{
                value: this.user.firstName + ' ' + this.user.lastName,
                disabled: true
            }, Validators.required],
            email: [{
                value: this.user.email,
                disabled: true
            }, [Validators.required, Validators.email, this.formValidators.isEmail]],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
        this.loading = false;
    }

    submit(): void {
        if (this.supportForm.invalid) return;
        this.loading = true;
        this.sendMailSupport(this.supportForm.getRawValue());
    }

    showAnswer(e: MatSelectChange) {
        this.subjectIndex = this.faq.findIndex(f => f.question === e.value);
    }

    private sendMailSupport(support): void {
        support.subject = faq[this.subjectIndex].question;
        support.message += '   Message envoyé par : ' + support.name + ' email : ' + support.email;
        this.apiService.post(`${this.endpoint}`, support)
            .subscribe({
                next: () => this._snackBar.open('Email envoyé', 'X'),
                error: () => {
                    console.error('Error while sending email to support');
                    this._snackBar.open('Une erreur est survenue, merci de réessayer', 'X');
                    this.loading = false;
                }, complete: () => this.loading = false
            });
    }
}
