import {Component, OnInit} from '@angular/core';
import {MsConsumersService, UserCached, UserService} from '@isifid/core';
import {UIService} from "../../../shared/services/ui.service";

@Component({
    selector: 'app-account-rewards',
    templateUrl: './rewards.component.html',
    styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {
    loading: boolean = true;
    marketplaceUrl: string = '';
    numbersRewards = ['0', '€'];
    private readonly user: UserCached;

    constructor(
        private readonly msConsumersService: MsConsumersService,
        public uiService: UIService,
        private readonly userService: UserService
    ) {
        this.user = this.userService.getUser();
    }

    ngOnInit() {
        if (!this.user && !this.user.consumerId) return;

        this.msConsumersService.getConsumer(this.user.consumerId).subscribe({
            next: (consumer) => {
                if (consumer.balance) this.numbersRewards = [...consumer.balance.toString(), '€'];
                this.generatePUrl();
            }, error: () => this.loading = false,
            complete: () => this.loading = false
        });
    }

    goToMarketplace() {
        if (this.marketplaceUrl) window.open(this.marketplaceUrl, '_blank');
    }

    generatePUrl(): void {
        if (!this.user || !this.userService.hasRole('CONSUMER')) return;
    }
}
