import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    CapitalizePipe,
    FormValidators,
    GiftUser,
    HierarchicalLevel,
    MsCoreService,
    MsServicesGiftService,
    User,
    UserService
} from '@isifid/core';
import {CustomValidator} from '../../../shared/helpers/custom.validator';
import {GiftService} from '../../../shared/services/gift.service';
import {Router} from '@angular/router';
import {forkJoin, Observable, of, switchMap, take, tap} from 'rxjs';
import {UIService} from '../../../shared/services/ui.service';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {DialogPersonalLinkComponent} from '../dialog-personal-link/dialog-personal-link.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-account-user',
    templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
    giftUserForm: FormGroup;
    loading: boolean;
    manager: User;
    hierarchyLevelForm: FormGroup;
    user: any;
    isCompleteAtLoading: boolean;
    title: string;
    level: HierarchicalLevel;
    giftUser: GiftUser;

    constructor(
        readonly uiService: UIService,
        readonly userService: UserService,
        private readonly dialog: MatDialog,
        private readonly giftUserService: GiftUserService,
        private readonly capitalizePipe: CapitalizePipe,
        private readonly msCoreService: MsCoreService,
        public readonly giftService: GiftService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        public readonly msServicesGiftService: MsServicesGiftService,
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        const urlParams = new URLSearchParams(window.location.search);

        if (!urlParams.get('uuid') && this.userService.hasRole('GIFT_ISIFID')) this.router.navigate(['home']).then();
        else this.init();
        this.loading = false;
    }

    openPersonalLinkDialog(): void {
        this.dialog.open(
            DialogPersonalLinkComponent,
            {
                data: this.user,
                maxWidth: 650,
                panelClass: 'dialog-panel'
            }
        );
    }

    update() {
        if (this.giftUserForm.invalid) {
            this.showErrors();
            return;
        }
        this.loading = true;
        this.updateUser()
            .pipe(
                tap(() => this.loading = false),
                switchMap(() => {
                    if (this.level?.role !== 'GIFT_ADVISOR') return of(null);
                    else {
                        // Update user entity
                        const branch = this.giftService.convertBranchCodeToString(this.giftUserForm.get('branch').value);
                        return (branch !== this.giftService.convertBranchCodeToString(this.giftUser.branchList[0])) ?
                            this.giftUserService.updateGiftUserBranchList(this.giftUser, [parseInt(branch)]) :
                            of(null);
                    }
                })
            )
            .subscribe({
                next: () => {
                    // Check if the profile complete after update
                    // If so, redirect to /account/favorite
                    if (!this.isCompleteAtLoading) {
                        this.giftService.checkIfAccountComplete();
                        if (this.giftService.isAccountComplete) {
                            // // Redirect managers to /account/staff, so they can configure branch
                            // if (this.userService.hasRole('GIFT_MANAGER')) this.router.navigate(['/account/staff']).then();
                            // else
                            this.router.navigate(['/account/favorite'], {queryParams: {force: true}}).then();
                        }
                    }
                }
            });
    }

    tmp(): void{
        this.router.navigate(['/account/favorite'], {queryParams: {force: true}}).then();
    }

    capitalizeInput(event: any) {
        this.giftUserForm.get(event.target.id).setValue(this.capitalizePipe.transform(event.target.value));
    }

    updateRole() {
        let url = this.giftService.giftNetworkVariables.url;
        if (!url || url === '') url = window.location.origin + '/auth';

        const invitationData = {
            clientId: this.giftUser.clientId,
            email: this.user.email,
            branchList: this.giftUser.branchList,
            url,
            levelId: this.hierarchyLevelForm.get('levelId').value
        };
        if (!this.userService.hasRole('GIFT_ADVISOR', this.user)) delete invitationData.branchList;

        this.loading = true;
        this.msServicesGiftService.updateGiftUserRights(this.giftUser.id, invitationData)
            .subscribe(() => this.init());
    }

    init() {
        this.loading = true;
        this.isCompleteAtLoading = this.giftService.isAccountComplete;
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get('uuid');

        if (uuid && this.userService.hasRole('GIFT_HQ')) {
            this.initUser(uuid).subscribe(() => this.initForms());
        } else {
            this.giftUserService.getGiftUser().pipe(take(1)).subscribe(s => this.giftUser = s);
            this.user = this.userService.getUser();
            this.initForms();
        }
    }

    private initForms(): void {
        this.level = this.giftService.hierarchicalLevels.find(l => l.id === this.giftUser.levelId);
        // It will only by used if user has role GIFT_HQ
        if (this.userService.hasRole('GIFT_HQ', this.user) && this.level) {
            this.hierarchyLevelForm = this.formBuilder.group({levelId: [this.level.id, Validators.required]});
        }

        this.giftUserForm = this.formBuilder.group({
            phone: [this.user?.phone || '', this.formValidators.isInternationalPhone],
            firstName: [this.user.firstName, [Validators.required, Validators.minLength(3)]],
            lastName: [this.user.lastName, [Validators.required, Validators.minLength(3)]],
            email: {value: this.user.email, disabled: true}
        });

        if (['GIFT_ADVISOR', 'GIFT_MANAGER'].includes(this.level?.role)) {
            const branchCode = this.giftService.getFirstBranchParsedAsString(this.giftUser.branchList);

            // Add form field for branch
            this.giftUserForm.addControl('branch', new FormControl(branchCode,
                [Validators.required, Validators.minLength(1), CustomValidator.isBranchCode]));
        }

        this.loading = false;
    }

    private initUser(uuid: string) {
        return new Observable(o => {
            forkJoin([
                this.msServicesGiftService.getGiftUserByUuid(uuid),
                this.msCoreService.getUser(uuid)
            ]).subscribe({
                next: ([giftUserData, userData]) => {
                    this.giftUser = giftUserData;
                    this.user = userData;
                    o.next();
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    private updateUser() {
        // Update user entity
        const userData = {};
        if (this.giftUserForm.get('firstName').value !== this.user.firstName) {
            this.user.firstName = this.giftUserForm.get('firstName').value;
            userData['firstName'] = this.user.firstName;
        }
        if (this.giftUserForm.get('lastName').value !== this.user.lastName) {
            this.user.lastName = this.giftUserForm.get('lastName').value;
            userData['lastName'] = this.user.lastName;
        }
        if (this.giftUserForm.get('phone').value !== this.user.phone) {
            this.user.phone = this.giftUserForm.get('phone').value;
            userData['phone'] = this.user.phone;
        }
        return Object.keys(userData).length ?
            this.giftUserService.updateUser(this.user, userData):
            of(null);
    }

    private showErrors(): void {
        for (const control in this.giftUserForm.controls) {
            if (this.giftUserForm.get(control).invalid) this.giftUserForm.get(control).markAsDirty();
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }
}
