import {Component} from '@angular/core';
import {AuthenticationService, MsServicesGiftService} from '@isifid/core';
import {Router} from '@angular/router';
import {finalize} from 'rxjs';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html'
})
export class LogoutComponent {
    loading = true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly router: Router
    ) {
        if (this.authenticationService.isAuth.value) this.authenticationService.logout(false, false);
        this.initPublicSettings();
    }

    private initPublicSettings(): void {
        this.msServicesGiftService.getSettingsByDomain()
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: settings => {
                    if (settings) this.router.navigate(['/login'], {queryParams: {from: 'logout'}}).then();
                },
                error: () => console.error('error while getting settings by domain')
            });
    }
}
