@if (!loading) {
    <div class="container-fluid pb-5 px-0 min-vh-100">
        <div class="row m-5 text-center">

            <div class="carousel slide p-0 mx-auto" data-bs-ride="carousel" id="carouselHP">
                <div class="carousel-inner p-0">
                    @for (slide of slides; track slide; let index = $index) {
                        <div [class.active]="index === 0" class="carousel-item">
                            <img [src]="slide" alt="Slide {{index}}" height="auto" width="100%"/>
                        </div>
                    }
                </div>
                @if (slides.length > 1) {
                    <div class="carousel-indicators">
                        @for (s of slides; track s; let index = $index) {
                            <button [attr.data-bs-slide-to]="index"
                                    [class.active]="index === 0" data-bs-target="#carouselHP" type="button"> </button>
                        }
                    </div>
                }
            </div>
        </div>

        <div class="row m-5">
            <div class="col-4">
                <div class="card w-100 d-inline-flex">
                    <div class="card-body">
                        <img alt="calendar icon" class="pb-4 icon" height="" src="../../../../assets/icons/calendar.svg"
                             width="">
                        <h2 class="pb-4">
                            <span class="title-border"></span>Opérations en cours
                        </h2>

                        <div class="card-text mb-3 text-grey">
                            Suivi des récompenses, voir menu en haut à droite
                            <i class="ms-2 fas fa-bars clickable"></i>
                        </div>

                        @if (operationsWithPlatform.length) {
                            <h6 class="mb-3 mt-2">
                                Opérations Récompenses nécessitant votre intervention
                                <i class="ms-2 fas fa-question-circle clickable"
                                   matTooltip="Les opérations figurant ci-dessous reposent sur votre implication,
                                    tant sur le plan administratif (inscrire un client) que commercial (exploiter l'attrait d'une récompense).
                                    Il vous suffit de cliquer sur l'opération concernée.">
                                </i>
                            </h6>

                            @for (operation of operationsWithPlatform; track operation.id) {
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                                        <mat-panel-title>
                                            <a class="clickable">{{ operation.name }}</a>
                                            <br>
                                            @if (operation.endDate) {
                                                <span> Jusqu'au {{ (operation.endDate | dateBefore: 1) | date:'dd/MM/yyyy' }}</span>
                                            } @else {
                                                <span> Sans échéance</span><br>
                                            }
                                            @if (operation.budgetRewardAvailable !== null) {
                                                <span> Budget Récompense disponible :
                                                    {{ operation.budgetRewardAvailable | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                                                </span>
                                            }
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="pt-3">
                                        @for (offer of operation?.offers; track offer.id) {
                                            <div>
                                                @if (hasEnoughCredits(operation.id)) {
                                                    <a [queryParams]="{operationId: operation.id, offerId: offer.id}"
                                                       [routerLink]="['/consumers/rewards/create']"
                                                       class="clickable">
                                                        {{ offer.amount }}€ - {{ offer.name }}
                                                    </a>
                                                } @else {
                                                    <span>
                                                    {{ offer.amount }}€ - {{ offer.name }}*
                                                </span>
                                                }
                                            </div>
                                        }
                                        @if (!hasEnoughCredits(operation.id)) {
                                            <p class="small">
                                                <br>* Votre quota de récompenses pour cette opération a été épuisé.
                                            </p>
                                        }
                                    </div>
                                </mat-expansion-panel>
                            }
                        }

                        @if (operationsWithoutPlatform.length) {
                            <h6 class="mb-3 mt-4">
                                Opérations Récompenses nécessitant simplement d'informer vos clients
                                <i class="ms-2 fas fa-question-circle clickable"
                                   matTooltip="Les opérations figurant ci-dessous reposent sur votre implication sur le plan commercial,
                                    c'est-à-dire informer votre client (ou lui rappeler) que l'acte concerné est assorti d'une récompense.
                                    En revanche, la distribution des récompenses est directement assurée par le siège,
                                    vous n'avez donc rien à saisir.">
                                </i>
                            </h6>

                            @for (operation of operationsWithoutPlatform; track operation.id) {
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                                        <mat-panel-title>
                                            <a class="clickable">{{ operation.name }}</a>
                                            <br>
                                            @if (operation.endDate) {
                                                <span> Jusqu'au {{ (operation.endDate | dateBefore: 1) | date:'dd/MM/yyyy' }}</span>
                                            } @else {
                                                <span> Sans échéance</span><br>
                                            }
                                            @if (operation.budgetRewardAvailable !== null) {
                                                <span> Budget Récompense disponible :
                                                    {{ operation.budgetRewardAvailable | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                                                </span>
                                            }
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="pt-3">
                                        @for (offer of operation?.offers; track offer.id) {
                                            <div>
                                            <span>
                                                {{ offer.amount }}€ - {{ offer.name }}*
                                            </span>
                                            </div>
                                        }
                                        <p class="small">
                                            <br>* Tous les clients souscripteurs de cette offre reçoivent
                                            automatiquement leurs
                                            récompenses, sans intervention de votre part. En revanche, c’est à vous de
                                            parler à
                                            votre client de cette récompense afin de faciliter sa prise de décision.
                                        </p>
                                    </div>
                                </mat-expansion-panel>
                            }
                        }
                        @if (!operationsWithoutPlatform.length && !operationsWithPlatform.length && sponsorshipService.hasSponsorship) {
                            <div>
                                <p class="card-text">
                                    La seule opération active en ce moment est l'opération de recommandation
                                    dont vous trouverez les détails dans le bloc ci-contre.
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div class="col-4">
                @if (sponsorshipService.hasSponsorship) {
                    <div class="card w-100 d-inline-flex mb-4">
                        <div class="card-body">
                            <img alt="handshake icon" class="pb-4 icon" height=""
                                 src="../../../../assets/icons/handshake.svg"
                                 width="">
                            <h2 class="pb-4">
                                <span class="title-border"></span>
                                {{ configurationService.getValueByKey(giftService.giftConfiguration, 'sponsorshipColumnTitle') || 'Recommandation' }}
                            </h2>
                            <div class="card-text">
                                @if (sponsorshipService.settings.online) {
                                    <span>
                                        <a [routerLink]="'/sponsorship/discover'">
                                        Découvrir</a> le circuit parrain-filleul
                                        <br>
                                    </span>
                                }
                                <a [routerLink]="['/sponsorship', 'sponsor', 'invite']">
                                    Inscrire</a> votre client comme parrain
                                <br>
                                <a [routerLink]="['/sponsorship', 'sponsored', 'check']">
                                    Accueillir</a> un filleul
                                <br>
                                <span>
                                    <a [routerLink]="'/sponsorship/tracking'">
                                        Suivi</a> parrainage
                                </span>
                                <br>
                                @if (sponsorshipService.settings.online) {
                                    <span>
                                        <a [routerLink]="'/sponsorship/qr-code'">
                                            Voir mon QR code</a> personnel
                                        <br>
                                    </span>
                                }

                                @if (sponsorshipOffers && sponsorshipOffers.length) {
                                    <mat-expansion-panel class="mt-2">
                                        <mat-expansion-panel-header [collapsedHeight]="'4vw'" [expandedHeight]="'4vw'">
                                            <mat-panel-title>
                                                <a class="clickable">Récompenses</a>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="pt-3">
                                            @for (offer of sponsorshipOffers; track offer.id) {
                                                @if (offer.status === 'active') {
                                                    <div>
                                                        @if (isOfferForSponsored(offer) && offer.amount > 0) {
                                                            <a [routerLink]="['/sponsorship', 'sponsored', 'check']"
                                                               class="clickable">
                                                                {{ offer.amount }}€ - {{ offer.name }}
                                                            </a>
                                                        } @else if (!isOfferForSponsored(offer) && offer.amount > 0) {
                                                            <a [routerLink]="['/sponsorship', 'sponsor', 'invite']"
                                                               class="clickable">
                                                                {{ offer.amount }}€ - {{ offer.name }}
                                                            </a>
                                                        }
                                                    </div>
                                                }
                                            }
                                        </div>
                                    </mat-expansion-panel>
                                }
                            </div>
                        </div>
                    </div>
                }

                @if (!uiService.rewardWithCashOnly) {
                    <div class="card w-100 d-inline-flex">
                        <div class="card-body">
                            <img alt="gift icon" class="pb-4 icon" height="" src="../../../../assets/icons/gift.svg"
                                 width="">
                            <h2 class="pb-4">
                                <span class="title-border"></span>Nature des récompenses
                            </h2>
                            <div class="card-text">
                                <a [routerLink]="'/how-to'">Simples à offrir,</a> simples à consommer
                                <br>
                                <a [routerLink]="'/gift-cards'">Des e-cartes-cadeaux</a> acceptées plus de 200 partenaires
                                <br>
                                <a href="https://cdn.isifid.com/partners/catalogue.pdf" target="_blank">
                                    Découvrez notre catalogue</a> d’idées cadeaux
                            </div>
                        </div>
                    </div>

                    <div class="d-none d-md-flex ps-3">
                        <a [href]="getMarketplaceUrl()" class="text-decoration-underline text-purple" target="_blank"
                           rel="noopener">
                            Accéder au site Récompense de démo <em class="fas fa-external-link-alt text-purple"></em>
                        </a>
                    </div>

                    <div class="d-none d-md-inline-block products">
                        @for (product of products; track index; let index = $index) {
                            <a [href]="product.link"
                               [style.background-image]="product.imageBackground"
                               class="text-center clickable mx-1 d-inline-block" matRipple target="_blank">
                                <div class="fw-bold pt-3 {{ !product.price2 ? 'mb-5':'mb-3'}}">
                                    {{ product.name }}
                                </div>
                                @if (!product.price2) {
                                    <h3 class="mt-3">{{ product.price }}€</h3>
                                } @else if (product.price2) {
                                    <h3 class="my-0 py-0">{{ product.price }}€</h3>
                                    <span class="d-block my-0 py-0">ou </span>
                                    <h3 class="my-0 py-0">{{ product.price2 }}€</h3>
                                }
                            </a>
                        }
                    </div>
                }
            </div>

            <div class="col-4">
                <app-news/>
            </div>

        </div>
    </div>
}
