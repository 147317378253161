import {Component, OnInit} from '@angular/core';
import {GiftService} from '../../../shared/services/gift.service';
import {MsServicesGiftService, Operation} from '@isifid/core';
import {concat, forkJoin, tap, toArray} from 'rxjs';
import {OperationsService} from '../../../shared/services/operations.service';

@Component({
    selector: 'app-account-credits',
    templateUrl: './credits.component.html',
    styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {
    loading: boolean = true;
    rows: any[];
    displayedColumns: string[];

    constructor(
        private readonly giftService: GiftService,
        private readonly operationsService: OperationsService,
        private readonly msServicesGiftService: MsServicesGiftService
    ) {
        this.displayedColumns = ['operation', 'reward', 'amount', 'end', 'action'];
    }

    ngOnInit() {
        forkJoin([
            this.msServicesGiftService.getAllCredits(),
            this.giftService.initBudgets(this.giftService.client.id)
        ]).subscribe({
            next: ([credits]) => {
                this.rows = [];
                credits.forEach(credit => {
                    const budget = this.giftService.budgets.find(b => b.id === credit.budgetId);
                    if (!budget) return;
                    const row = {
                        creditId: credit.id,
                        id: credit.id,
                        budgetId: credit.budgetId,
                        operationId: budget.operationId,
                        amountRemaining: credit.amountRemaining,
                        endAt: budget.endAt
                    };
                    this.rows.push(row);
                });
            }, complete: () => this.updateRowValues()
        });
    }

    updateRowValues() {
        let operation: Operation;
        const cleanRows = [];
        const rows = this.rows.filter(row => {
            if (this.operationsService.getOperations().find(o => o.id === row.operationId)) return true;
            else {
                console.error(`No active operation found for operationId : ${row.operationId} budgetId : ${row.budgetId}`);
                return false;
            }
        });

        const observers = rows.map(row => {
            operation = this.operationsService.getOperations().find(o => o.id === row.operationId);
            return this.operationsService.getOffersByOperationId(operation.id)
                .pipe(
                    tap(offers => {
                        // Sort by amount asc to get the cheapest offer first
                        offers.sort((a, b) => Number(a.amount) - Number(b.amount));
                        const offer = offers.filter(o => o.status === 'active')[0];
                        if (!offer) console.error(`No active offer found for operation : ${operation.name}, operationId : ${operation.id}`);
                        else {
                            row.operation = operation.name;
                            row.offerId = offer.id;
                            row.rewardAmount = offer.amount;
                            cleanRows.push(row);
                        }
                    })
                );
        });

        concat(...observers)
            .pipe(toArray())
            .subscribe({
                next: () => {
                    this.rows = cleanRows;
                    this.loading = false;
                }
            });
    }
}
