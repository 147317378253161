<div class="w-100 text-center" style="position: absolute; top: 20%;">
    @if (loading) {
        <div>
            <h1> Déconnexion </h1>
            <div class="fa-3x">
                <em class="fas fa-sync fa-spin"></em>
            </div>
        </div>
    } @else {
        <div>
            <h2 class="px-5">
                Vous êtes déconnecté(e). Merci de bien vouloir vous reconnecter pour accéder au service.
            </h2>
        </div>
    }
</div>
