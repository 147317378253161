<div class="container-fluid">

    <div class="row my-3 text-center">
        <h1>Attribuer une récompense<br> à un client</h1>
    </div>

    <div class="row">
        @if (!loading) {
            <div class="col-12 col-md-6 col-xl-5 mx-xl-auto px-md-5">
                <app-operations-bar (offerEvent)="updateOffer($event)"
                                    (operationEvent)="updateOperation($event)"
                                    (canRewardWithCreditEvent)="updateCanRewardWithCredit($event)"
                                    [selectedOfferId]="+offer?.id"
                                    [selectedOperationId]="+operation?.id" />
                @if (!offer) {
                    <p class="px-3 text-red">
                        Merci de sélectionner une offre pour attribuer une récompense
                    </p>
                }
            </div>
        }
        <div class="col-12 col-md-6 col-xl-5 mx-xl-auto px-md-5">

            @if (!loading && !consumerIsRewarded) {
                <app-reward-form (consumerEvent)="updateConsumer($event)"
                                 [resetForm]="resetConsumerForm"
                                 [offer]="offer"
                                 [operation]="operation" />
            }
            @if (rewarding && consumerCanBeRewarded.operationCheckFail) {
                <div class="text-center mt-3">
                    <h3>Votre client a deja été récompensé pour cette opération</h3>
                </div>
            }
            @if (rewarding && consumerCanBeRewarded.offerCheckFail && !consumerCanBeRewarded.operationCheckFail) {
                <div class="text-center mt-3">
                    <h3>Votre client a deja été récompensé pour cette offre</h3>
                </div>
            }
            @if (rewarding && operation.budget && consumerCanBeRewarded.budgetCheckFail) {
                <div class="text-center mt-3">
                    <h3>Le quota de récompenses pour cette opération a été épuisée.</h3>
                </div>
            }

            @if (consumerIsRewarded) {
                <div class="text-center">
                    <p class="mt-5 mb-3 mx-auto">Cliquer ici pour voir la fiche de votre client.</p>
                    <button [routerLink]="'/consumers/' + consumer.id"
                            class="btn btn-validate mx-auto" data-cy="consumer-account">
                        Fiche client
                    </button>
                </div>
            }
            @if (consumerIsRewarded || rewarding) {
                <div class="text-center mt-3">
                    <button class="btn btn-validate mx-auto" (click)="resetOfferAndConsumer()">
                        Récompenser un autre client
                    </button>
                </div>
            }

            @if (consumerIsRewarded && sponsorshipService.hasSponsorship && !isSponsor && canDisplayBecomeSponsorButton) {
                <app-sponsor-age-form
                    [consumer]="this.consumer"
                    [sponsorshipUser]="sponsorshipUser"
                    (updateSponsorshipUser)="updateSponsorshipUser($event)" />
            }

        </div>
    </div>

</div>
