<div class="container-fluid py-5">
    <h1 class="my-5 px-5 text-center">Suivi de récompenses de vos opérations</h1>
    <p class="my-5 text-center">
        Retrouver sur cette page l’historique de vos clients récompenses. Vous pouvez les consulter
        soit pour vous-même conseiller clientèle soit pour l’ensemble des conseillers de votre agence.<br><br>
    </p>

    @if (trackingForm) {
        <form [formGroup]="trackingForm">
            <div class="row justify-content-center gy-2">
                @if (branchList.length > 1) {
                    <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                        <mat-label>Code agence ou point de vente</mat-label>
                        <mat-select formControlName="branchCode">
                            <mat-option value="">Tous</mat-option>
                            @for (item of branchList; track item) {
                                <mat-option [value]="item">
                                    {{item}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                    <mat-label>Operation</mat-label>
                    <mat-select formControlName="operationId">
                        @for (item of operations; track item.id) {
                            <mat-option [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                    <mat-label>Date début</mat-label>
                    <input matInput [matDatepicker]="start" formControlName="start">
                    <mat-datepicker-toggle matIconSuffix [for]="start" />
                    <mat-datepicker #start />
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                    <mat-label>Date fin</mat-label>
                    <input matInput [matDatepicker]="end" formControlName="end">
                    <mat-datepicker-toggle matIconSuffix [for]="end" />
                    <mat-datepicker #end />
                </mat-form-field>
            </div>
            <div class="row justify-content-center text-center mt-2 gy-2">
                @if (expiredOperation) {
                    <mat-checkbox  formControlName="useAllOperations"
                                   id="disabledOperation"
                                   class="col-sm-12">
                        Inclure les opérations inactives
                    </mat-checkbox>
                }
                <mat-checkbox  formControlName="anonymizeConsumersData"
                               id="anonymize"
                               class="col-sm-12">
                    Anonymiser les données bénéficiaires
                </mat-checkbox>
            </div>
            <div class="row justify-content-center">
                <button (click)="getStats()" class="btn btn-validate col-auto m-3 px-4 py-2" data-cy="button-research" style="min-width: 130px">
                    @if (!loading) {Recherche}
                    @else {<em class="fas fa-spinner fa-spin"></em>}
                </button>
                <button (click)="resetForm()" class="btn btn-black col-auto m-3 px-4 py-2">
                    Reset
                </button>
            </div>
            @if (dataSource?.data.length > 0 && !loading) {
                <div class="row">
                    <div class="col mx-auto text-center">
                        <button (click)="download()" class="btn btn-validate m-3 px-4 py-2">
                            <i aria-hidden="true" class="fas fa-file-excel mx-2"></i> Export Excel
                        </button>
                    </div>
                </div>
            }
        </form>
    }

    <div class="responsive-table mt-5" [hidden]="!(dataSource?.data.length > 0)">
        <div class="responsive-table-body">
            <table [dataSource]="dataSource" aria-describedby="tracking" mat-table matSort>
                @for (col of displayedColumns; track col; let index = $index) {
                    <ng-container [matColumnDef]="col">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{colNames[index]}}</th>
                        <td *matCellDef="let element" mat-cell>{{element[col]}}</td>
                    </ng-container>
                }

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons />
    </div>

    @if (dataSource?.data.length === 0) {
        <div class="text-center my-5">Pas de client trouvé</div>
    }
</div>
