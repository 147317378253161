@if (!loading) {
    <div class="container-fluid text-center pt-5 lh-1">
        <div class="row">
            <div class="col-10 col-lg-8 mx-auto">
                @if (!uiService.rewardWithCash) {
                    <div class="mt-3">
                        <h3 class="text-purple lh-base">
                            Mes récompenses personnelles sont des cartes cadeau identiques<br>
                            aux récompenses Premium offertes à mes clients.
                        </h3>

                        <p>La somme dont je dispose actuellement pour me procurer des cartes cadeau s’élève à :</p>
                    </div>
                } @else {
                    <h1 class="text-purple mt-3 lh-base">Mes récompenses personnelles.</h1>

                    <p>La somme dont je dispose actuellement s’élève à :</p>
                }

                <div class="d-flex justify-content-center mt-5 mb-3">
                    @for (number of numbersRewards; track number) {
                        <div class="reward-number mx-2 text-dark-blue fw-bold bg-grey px-3 py-1">
                            {{ number }}
                        </div>
                    }
                </div>

                <button (click)="goToMarketplace()" [disabled]="!marketplaceUrl"
                        class="btn btn-validate mt-3 mb-5 ">
                    Me rendre sur mon espace récompense
                </button>

            </div>
        </div>
    </div>
}
