<div [style.background-image]="'url(\'' + backgroundImg + '\')'"
     class="container-fluid favorite bg-custom p-2 p-md-5 min-vh-100 max-vw-100">

    <div class="container p-5 mx-auto bg-white">
        <h2 class="my-5 text-center">
                Pour être directement connecté(e) en un clic à votre espace personnel Gift, il suffit d'ajouter cette page à vos favoris
        </h2>

        <div>
            <p>Pour ajouter un site à vos favoris, plusieurs options s'offrent à vous, au choix :</p>
            <ul>
                <li>Appuyer sur CTRL + D</li>
                <li>Appuyer sur l'étoile en haut à droite de votre écran</li>
            </ul>
            <p>Une fois ce lien de connexion automatique ajouté à vos favoris, il vous suffira de le
                retrouver dans
                la liste de favoris de votre navigateur, de cliquer et vous serez ainsi connecté(e). Il ne
                sera plus
                nécessaire d’entrer vos informations et d’utiliser la connexion par email.</p>
        </div>

        @if (showForm) {
            <div class="text-center mt-4">
                <h5>Merci de confirmer cet ajout dans vos favoris en cliquant ci-dessous</h5>
            </div>
            <div class="mt-5 text-center" >
                <mat-checkbox [(ngModel)]="isChecked">J'ai ajouté l'assistant Gift à mes favoris</mat-checkbox>
                <div class="mt-2">
                    <button class="btn-validate px-4 py-2"
                            data-cy="home"
                            (click)="goHome()"
                            type="button"
                            [disabled]="!isChecked">Accéder
                    </button>
                </div>
            </div>
        }
    </div>
</div>
