import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GiftService} from '../../../shared/services/gift.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    AuthenticationService,
    CacheService, ConfigurationService,
    CrispService, FormValidators,
    MsServicesGiftService,
    PublicGiftSettings,
    UserCached
} from '@isifid/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-account-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    accessSent: boolean;
    settings: PublicGiftSettings;
    error: boolean = false;
    loading: boolean;
    loginForm: FormGroup;
    inviteOnly: boolean = false;
    isComingFromLogout: boolean = false;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly cacheService: CacheService,
        public crispService: CrispService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly giftService: GiftService,
        private readonly msServicesGiftService: MsServicesGiftService,
        public readonly configurationService: ConfigurationService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly location: Location
    ) {
        this.loading = true;
        this.accessSent = false;
        this.route.queryParams.subscribe(s => {
            this.isComingFromLogout = s.from === 'logout';
            if (this.isComingFromLogout) this.location.replaceState('/login');
        });
        // If the user is auth, redirect to home
        if (this.authenticationService.isAuthenticated()) {
            this.router.navigate(['/home']).then();
        } else {
            this.cacheService.clearAllCache();
            this.initPublicSettings();
        }
    }

    initCrisp(domain): void {
        const user = new UserCached();
        user.firstName = 'Visiteur - ';
        user.lastName = domain;
        user.email = '';
        this.crispService.init(this.settings.clientId, '8a412d88-127b-4729-b292-f0718db417cc').subscribe();
        this.crispService.initUser(user).subscribe();
    }

    initPublicSettings(): void {
        // Get a small client entity, enough to display logo / name / colors
        this.msServicesGiftService.getSettingsByDomain().subscribe({
            next: (settings) => {
                // Logout if couldn't find the template
                if (!settings) this.router.navigate(['/logout']).then();
                this.settings = settings;
                this.inviteOnly = this.configurationService.getValueByKey(settings.configuration, 'inviteOnly');
                this.initHtmlData(this.settings.networkName);
                this.initCrisp(this.settings.domain);
            }, error: () => {
                console.error('error while getting settings by domain');
                this.router.navigate(['/logout']).then();
            }
        });
    }

    initHtmlData(networkName): void {
        this.giftService.initGiftNetworkVariables(networkName).subscribe({
            next: () => {
                this.loginForm = this.formBuilder.group({
                    // Force email to contain a dot before @ : .+[.]+.+[@].+
                    // Allow one number before @ : ^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+
                    email: [
                        this.settings?.emailAddressRoot || '',
                        [
                            Validators.required, Validators.email,
                            Validators.pattern('^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+')
                        ]
                    ]
                });
                this.loading = false;
            }, error: () => {
                console.error('error while init Gift network variables');
                this.router.navigate(['/logout']).then();
            }
        });
    }

    getAccess(): void {
        if (this.loginForm.invalid) {
            this.showErrors();
            return;
        }

        this.loading = true;
        let url = this.giftService.giftNetworkVariables.url;
        if (!url || url === '') url = window.location.origin + '/auth';
        let emailSender = this.giftService.giftNetworkVariables.emailSender;
        if (!emailSender || emailSender === '') emailSender = null;

        const accessData = {
            clientId: this.settings.clientId,
            email: this.loginForm.get('email').value,
            emailSender,
            url
        };

        // Init login
        this.initLogin(accessData);
    }

    private initLogin(accessData) {
        // Init login
        this.msServicesGiftService.getAccess(accessData).subscribe({
            next: () => {
                this.accessSent = true;
            }, error: () => {
                this.error = true;
                console.error('error while getting access');
                this.loading = false;
            }, complete: () => this.loading = false
        });
    }

    private showErrors(): void {
        for (const control in this.loginForm.controls) {
            if (this.loginForm.get(control).invalid) {
                this.loginForm.get(control).markAsDirty();
            }
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }
}
