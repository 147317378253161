import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ConsumersRoutingModule} from './consumers-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {AccountComponent} from './account/account.component';
import {RewardComponent} from './reward/reward.component';
import {SearchComponent} from './search/search.component';

import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RewardFormComponent} from './reward/reward-form/reward-form.component';
import {TrackingComponent} from './tracking/tracking.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {SponsorAgeFormComponent} from './reward/sponsor-age-form/sponsor-age-form.component';

@NgModule({
    declarations: [
        AccountComponent,
        SearchComponent,
        RewardComponent,
        RewardFormComponent,
        TrackingComponent,
        SponsorAgeFormComponent
    ],
    imports: [
        CommonModule,
        ConsumersRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        FormsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule
    ]
})
export class ConsumersModule {
}
