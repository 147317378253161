import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {trackGuardFn} from '@isifid/core';

const routes: Routes = [
    {
        path: 'auth',
        component: AuthComponent
    },
    {
        path: 'login',
        canActivate: [trackGuardFn],
        component: LoginComponent
    },
    {
        path: 'logout',
        canActivate: [trackGuardFn],
        component: LogoutComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule {
}
