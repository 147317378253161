import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
    FormValidators,
    GiftUser,
    MsCoreService,
    MsServicesGiftService,
    UserCached,
    UserService
} from '@isifid/core';
import {MatSort} from '@angular/material/sort';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {GiftService} from '../../../shared/services/gift.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, finalize, forkJoin, map, Observable, of, switchMap, take, tap} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatChipInputEvent} from '@angular/material/chips';
import {GiftUserService} from '../../../shared/services/gift-user.service';

@Component({
    selector: 'app-account-staff',
    templateUrl: './staff.component.html'
})
export class StaffComponent {
    addManagerForm: FormGroup;
    branchList = [];
    isManagerAccountCompleteAtLoading: boolean;
    title: string;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: Array<string>;
    hideTable: boolean = true;
    loading: boolean;
    user: UserCached;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    private giftUser: GiftUser;

    constructor(
        private cdRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly msCoreService: MsCoreService,
        private readonly router: Router,
        public readonly userService: UserService
    ) {
        this.displayedColumns = ['branchCode', 'firstName', 'lastName', 'email', 'updatedAt'];
        this.isManagerAccountCompleteAtLoading = giftService.isManagerAccountComplete;
        // Subscribe to queryPrams to detect change in url
        this.route.queryParams.subscribe((urlParams) => {
            this.init(urlParams);
        });
    }

    removeBranch(branch: any) {
        const index = this.branchList.indexOf(branch);
        this.branchList.splice(index, 1);
    }

    addBranch(event: MatChipInputEvent) {
        // Only allow number for branchCode
        if (!event.value || isNaN(Number(event.value))) return;
        if (this.branchList.includes(event.value)) return;

        this.branchList.push(event.value);
        this.branchList.sort((a, b) => a - b);
        // Clear the input value
        event.chipInput.clear();
    }

    updateManagerBranchList() {
        this.loading = true;
        const branchLIst = this.branchList.map(branchCode => Number(branchCode));

        // Update the branchList
        this.giftUserService.updateGiftUserBranchList(this.giftUser, branchLIst)
            .subscribe(() => {
                // Refresh the manager entity
                // if (this.userService.getUser().uuid === this.giftUser.manager) await lastValueFrom(this.giftUserService.init(this.giftUser.manager));

                // Check if the profile complete after update
                this.giftService.checkIfManagerAccountComplete();

                this.route.queryParams.subscribe(urlParams => {
                    this.init(urlParams);
                });

                // Check if the profile complete after update
                // If so, redirect to /favorite
                if (!this.isManagerAccountCompleteAtLoading && this.giftService.isManagerAccountComplete) this.router.navigate(['/favorite']).then();
            });
    }

    openDialog(user) {
        if (!this.userService.hasRole('GIFT_DIRECTOR', this.user)) return;
        this.dialog.open(DialogAdvisorComponent, {width: '95%', data: {manager: user}});
    }

    addManager() {
        if (this.addManagerForm.invalid) return;
        this.loading = true;

        this.invite(this.addManagerForm.get('email').value.trim())
            .subscribe(() => {
                // Refresh the manager entity
                // if (this.userService.getUser().uuid === this.giftUser.manager) await lastValueFrom(this.giftUserService.init(this.giftUser.manager));

                this.route.queryParams.subscribe(urlParams => {
                    this.init(urlParams);
                });
            });
    }

    invite(email): Observable<any> {
        let url = this.giftService.giftNetworkVariables.url;
        if (!url || url === '') url = window.location.origin + '/auth';
        let emailSender = this.giftService.giftNetworkVariables.emailSender;
        if (!emailSender || emailSender === '') emailSender = null;

        const invitationData = {
            email: email,
            clientId: this.giftService.settings.clientId,
            emailSender,
            url,
            sendAccess: false,
            forceSendAccess: false,
            managerUuid: this.user.uuid,
            role: 'manager'
        };

        // Init login for new member
        return this.msServicesGiftService.postInvitation(invitationData).pipe(catchError(() => of(null)));
    }

    extractUsers() {
        const aoa = [[]];
        aoa[0] = this.dataSource.data.map((user) => [user.lastName, user.firstName, user.email, user.branchCode || user.branchList?.toString() || '']);
        aoa[0].unshift(['No2m', 'Prénom', 'Email', 'Point de vente']);
        this.excelService.exportAsExcelFileAoA(aoa, 'utilisateurs-gift', [this.title]);
    }

    private init(urlParams) {
        this.loading = true;
        this.user = this.userService.getUser();
        this.title = 'Mon équipe';
        this.giftUserService.getGiftUser().pipe(take(1)).subscribe(s => this.giftUser = s);
        if (this.userService.hasRole('GIFT_HQ')) {
            this.initAsHq(urlParams)
                .pipe(
                    finalize(() => {
                        this.userService.hasRole('GIFT_DIRECTOR', this.user) ?
                            // Impersonate director
                            this.impersonateDirector():
                            this.initBranchAndUsers();
                    })
                )
                .subscribe();
        } else if (this.userService.hasRole('GIFT_DIRECTOR')) this.impersonateDirector();
        else this.initBranchAndUsers();
    }

    private initDirectorForm() {
        this.addManagerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, this.formValidators.isEmail]]
        });
    }

    private initAsHq(urlParams): Observable<any> {
        const uuid = urlParams.uuid;
        // If no uuid return (no impersonate)
        if (!uuid) return of(null);

        return forkJoin([
            this.msServicesGiftService.getGiftUserByUuid(uuid),
            this.msCoreService.getUser(uuid)
        ]).pipe(
            tap(([giftUserData, userData]) => {
                this.giftUser = giftUserData;
                for (const key in userData) {
                    if ((this.user as object).hasOwnProperty(key)) {
                        // Need to flatten roles in order to make hasRole works
                        this.user[key] = key === 'roles' ? userData[key].map(s => s.name) : userData[key];
                    }
                }

                this.title = this.user.firstName && this.user.lastName ?
                    `Équipe de ${this.user.firstName} ${this.user.lastName}` :
                    `Équipe de ${this.user.email}`;
            })
        );
    }

    private initDirector() {
        this.displayedColumns = ['firstName', 'lastName', 'email', 'updatedAt'];
        this.branchList = [...this.giftUser.branchList];
        this.giftUserService.getGiftUsersByManagerUuid(this.user.uuid)
            .subscribe({
                next: managers => this.initBranchAndManagers(managers),
                error: () => this.loading = false
            });
    }

    private initBranchAndUsers() {
        if (!this.giftUser?.branchList) return;
        this.branchList = [];

        this.branchList = this.giftUser.branchList.map(s => this.giftService.convertBranchCodeToString(s));
        this.giftUserService.getGiftUsersByManagerUuid(this.user.uuid)
            .pipe(
                switchMap(s => {
                    return s.length === 0 ?
                        of([]) :
                        forkJoin(
                            s.map(u => {
                                return this.msCoreService.getUser(u.uuid)
                                    .pipe(
                                        map(user => ({
                                            firstName: user.firstName,
                                            lastName: user.lastName,
                                            email: user.email,
                                            branchCode: u.branchList[0],
                                            updatedAt: u.updatedAt
                                        })),
                                        catchError(() => of(null))
                                    );
                            })
                        );
                }),
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: users => {
                    // Don't display manager in its team
                    users = users.filter(u => u && u.uuid !== this.user.uuid);

                    this.dataSource = new MatTableDataSource<unknown>(users);
                    this.dataSource.sort = this.sort;

                    if (this.branchList.length) {
                        this.cdRef.detectChanges();
                        // Custom paginator
                        this.paginator._intl.itemsPerPageLabel = 'Conseillers par page';
                        this.dataSource.paginator = this.paginator;
                        this.hideTable = false;
                    }
                }
            });
    }

    private initBranchAndManagers(managers) {
        const users = [];
        // Flatten managers
        managers.forEach(branchUsers => {
            branchUsers.forEach(u => {
                // Only keep manager if manager = user uuid
                if (u.manager === this.user.uuid && !users.find(us => us.uuid === u.uuid)) users.push(u);
            });
        });
        // Remove current user from table
        this.dataSource = new MatTableDataSource<unknown>(users.filter(u => u.email !== this.user.email));
        this.dataSource.sort = this.sort;

        this.cdRef.detectChanges();
        // Custom paginator
        this.paginator._intl.itemsPerPageLabel = 'Managers par page';
        this.dataSource.paginator = this.paginator;
        this.hideTable = false;
        this.loading = false;
    }

    private impersonateDirector(): void {
        // Impersonate director
        this.initDirectorForm();
        if (!this.giftUser.branchList.length) this.loading = false;
        else this.initDirector();
    }
}

export interface DialogData {
    manager;
}

@Component({
    selector: 'app-dialog-advisor',
    templateUrl: 'dialog-advisors.html'
})
export class DialogAdvisorComponent implements OnInit {
    dataSource = new MatTableDataSource<any>();
    displayedColumns = ['branchCode', 'firstName', 'lastName', 'email', 'updatedAt'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    loading: boolean = true;

    constructor(
        private cdRef: ChangeDetectorRef,
        private readonly msCoreService: MsCoreService,
        private readonly giftUserService: GiftUserService,
        private readonly userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    ngOnInit() {
        this.giftUserService.getGiftUsersByManagerUuid(this.userService.getUser().uuid, this.data.manager.branchList)
            .pipe(
                switchMap(branchUsers => {
                    return forkJoin(
                        branchUsers.map(u => {
                            return this.msCoreService.getUser(u.uuid)
                                .pipe(
                                    map(user => ({
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        email: user.email,
                                        branchCode: u.branchList[0],
                                        updatedAt: u.updatedAt
                                    })),
                                    catchError(() => of(null))
                                );
                        })
                    );
                })
            )
            .subscribe({
                next: users => {
                    this.dataSource = new MatTableDataSource<unknown>(users.filter(s => s));
                    this.dataSource.sort = this.sort;
                    this.cdRef.detectChanges();
                    // Custom paginator
                    this.paginator._intl.itemsPerPageLabel = 'Conseillers par page';
                    this.dataSource.paginator = this.paginator;
                    this.loading = false;
                }
            });
    }
}
