<div class="container-fluid">
    <div class="row">

        <div class="col p-5">
            <div class="my-4">
                <h1>Des e-cartes-cadeaux acceptées chez plus de 200 partenaires</h1>
            </div>
            <div class="my-5">
                <h2 class="text-purple">Plus de 200 sites sélectionnés</h2>
                <p>des plus grands comme The Fork aux plus insolites comme Le Slip Français.<br>
                    Ces sites participent au financement des récompenses.
                </p>
            </div>
            <div class="my-5">
                <h2 class="text-purple">Des cartes cadeaux de 20 à 50€ *</h2>
                <p>que votre client se procure avec la cagnotte qui lui a été offerte. Chaque carte
                    est à usage unique.
                    <br>
                    <span class="small fst-italic">
                        Exemple : une opération offre 80€ aux clients qui souscrivent à tel produit. Le client va consommer
                        une carte cadeau de 50€ chez The Fork et une carte cadeau de 30€ chez Blissim.
                        <br>
                        Même s’il reçoit une nouvelle récompense, ce client ne pourra plus commander chez The Fork ou Blissim.
                        <br>
                        *fourchette moyenne
                        @if (uiService.rewardWithRegular) {
                            <span>dépendante du type de cagnotte (voir paragraphe suivant)</span>
                        }
                    </span>
                </p>
            </div>
            @if (uiService.rewardWithRegular) {
                <div class="my-5">
                    <h2 class="text-purple">
                        Cagnottes Regular / Cagnottes Premium
                    </h2>
                    <p>Il existe 2 types de cagnottes :<br>
                        les <a [href]="getGiftSiteUrl()" class="text-decoration-underline" target="_blank" rel="noopener">
                            cagnottes Regular
                            <em class="fas fa-external-link-alt"></em></a>
                        et les <a [href]="getGiftSiteUrl(true)" class="text-decoration-underline" rel="noopener"
                                  target="_blank">cagnottes Premium&nbsp;<em class="fas fa-external-link-alt"></em>
                        </a>.
                        <br>
                        Les cagnottes Premium sont plus coûteuses pour la banque mais présentent des cartes cadeaux de plus
                        grande valeur.
                        <br>
                        <span class="small fst-italic">Exemple : dans une cagnotte Regular, la carte Aquarelle s’élève à
                            25€. Dans une cagnotte Premium, la carte Aquarelle s’élève à 50€.
                        </span>
                    </p>
                </div>
            }
        </div>

        <div class="col-5 p-5">

            <div class="row justify-content-center px-5">
                <div class="col d-flex text-center px-5">
                    <img [src]="env.cdnUrl + '/gift/img/gift-cards/partnerLogo.png'"
                         alt="logo" class="mx-auto" height="auto" width="auto">
                    <img [src]="env.cdnUrl + '/gift/img/gift-cards/giftcard.png'"
                         alt="gift card" class="mx-auto" height="auto" width="auto">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-10 mx-auto text-center p-2">
                    <img [src]="env.cdnUrl + '/gift/img/gift-cards/marketplace.jpg'"
                         alt="smartphone" height="auto" width="330">
                </div>
            </div>

        </div>
    </div>
</div>
